
<template>
  <div>
    <div v-if="userData && userData.role === 'ROLE_PARTNER'" class="invoice-addresses-container">
      <h4>{{ $t("partner.invoiceInfo") }}</h4>
      <div class="invoice-addresses">
        <b-form-radio v-model="selected" name="address" :value="userData.id">
          <div class="invoice-addresses-address">
            {{ userData.companyName }} <br>
            {{ $t("partner.ico") }}: {{ userData.ico }}<br>
            {{ $t("partner.dic") }}: {{ userData.dic }}<br>
            <router-link :to="`/partner-invoices/${userData.id}/edit-invoice-address`">{{ $t("forms.edit") }}</router-link>
          </div>
        </b-form-radio>
      </div>

      <div class="invoice-addresses-actions">
        <!--<b-button to="/partner-invoices/add-invoice-address" variant="primary">{{ $t("invoice.invoicesAddressAdd") }}</b-button>-->
        <b-button v-if="cfContract" :href="cfContract.value" target="_blank" variant="gray">{{ $t("partner.showContract") }}</b-button>
        <b-button v-if="cfPricelist" :href="cfPricelist.value" target="_blank" variant="gray">{{ $t("partner.showPricelist") }}</b-button>
      </div>
    </div>
    <div v-if="rows" class="custom-table mb-4">
      <table-actions
        :actions="['perPage']"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="{}"
        @is-finished="isLoading = false"
      >
        <template v-slot:invoice="data">
          <a target="_blank" :href="data.value.url">{{ data.value.name }}</a>
        </template>
        <template v-slot:issued="data"> {{ $helper.formatDate(data.value.createdAt)}} </template>
        <template v-slot:interval="data">
          {{ $helper.formatDate(data.value.fromDate)}} - {{ $helper.formatDate(data.value.toDate)}}
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <a target="_blank" :href="$helper.getUrlPreventBrowserCache(data.value.notCachedUrl)"><img src="@/assets/images/icons/eye.svg" /></a>
            <custom-btn v-if="userData && userData.role === 'ROLE_ADMIN'" @click="regenerateInvoice(data.value)" icon="arrow-repeat" />
            <delete-btn v-if="userData && userData.role === 'ROLE_ADMIN'" @pressDelete="deleteInvoice(data.value['@id'])" />
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-if="pages"
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import CustomBtn from "@/components/Buttons/CustomBtn.vue";
import {useToast} from "vue-toastification";
import Toast from "@/components/Toast.vue";

export default {
  // TODO - add address
  components: {
    DeleteBtn,
    CustomBtn,
    TableLite,
    TablePagination,
    TableActions,
  },
  props: {
    partnerObj: { type: Object, default: null}
  },
  mixins: [ResourceUtils],
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      cfContract: null,
      cfPricelist: null,
      search: "",
      isLoading: false,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t("invoice.invoice"),
          field: "invoice",
          sortable: false,
        },
        {
          label: this.$t("invoice.issued"),
          field: "issued",
          sortable: false,
        },
        {
          label: this.$t("invoice.interval"),
          field: "interval",
          sortable: false,
        },
        {
          label: this.$t("invoice.actions"),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.cfContract = this.userData.customFields.find(cf => cf.name === 'partner_contractUrl')
    this.cfPricelist = this.userData.customFields.find(cf => cf.name === 'partner_pricelistUrl')
    this.loadInvoices();
  },
  methods: {
    loadInvoices() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        "order[fromDate]": "desc"
      };
      if (this.partnerObj && this.partnerObj) {
        params.partner = this.partnerObj.id;
      }
      this.$Pdfs.getCollection({ params }).then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];
      });
    },
    deleteInvoice(iri) {
        this.deleteByUrl(
            this.$Pdfs,
            iri,
            this.$t("pdf.deleted"),
            null,
            this.loadInvoices
        );
    },
    regenerateInvoice(pdf) {
        this.$Pdfs.updateResourceByUrl({ url: `/pdf/invoice-partner/${pdf.name}`, body: pdf })
            .then((response) => {
                if (response.status === 200) {
                    this.pdf = response.data
                    const link = document.createElement("a");
                    link.href = this.$helper.getUrlPreventBrowserCache(response.data.notCachedUrl);
                    link.target = "_blank";
                    link.click();
                }
            })
            .catch((error) => {
                if (error.response) {
                    this.$helper.showToast(
                        useToast(),
                        Toast,
                        error.response.data["hydra:title"],
                        error.response.data["hydra:description"],
                        "danger"
                    );
                }
            });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadInvoices();
    },
    statusClass(status) {
      let className = "success";
      if (!status) {
        className = "inactive";
      }
      return className;
    },
    error() {
      this.$emit("clearAction");
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadInvoices();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadInvoices();
    },
    onPageChange() {
      this.loadInvoices();
    },
  },
};
</script>
